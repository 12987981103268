import React, { useCallback, useEffect, useRef, useState } from "react"
import TrackImage from "./TrackImage"
import { useGetTracks } from "../../gql/useGetTracks"
import Loading from "../Loading"
import { Link } from "gatsby"
import {
  BodyWrapper,
  Row,
  GroupTitle,
  TrackImageContainer,
  TrackList,
} from "../../styles/trackAndSeries.style"
import SearchHeader from "../SearchHeader"

export default function TrackPage() {
  const [offset, setOffset] = useState(0)
  const [tracksList, setTracksList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [queryString, setQueryString] = useState(null)
  const [search, setSearch] = useState(null)

  let alphabet = "A"

  const { loading, error, data } = useGetTracks({
    offset,
    ...(queryString ? { queryString } : null),
  })

  const tracks = !loading && data ? data.getTracksV2 : []

  const observer = useRef()
  const lastTrackRef = useCallback(
    node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(tracks => {
        if (tracks[0].isIntersecting && hasMore) {
          setOffset(offset => offset + 10)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    const fetchMoreData = () => {
      if (offset === 0) setTracksList(tracks)
      else setTracksList(tracksList => tracksList.concat(tracks))
      setHasMore(tracks.length > 0)
    }

    fetchMoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onSearch = () => {
    setQueryString(search)
    setOffset(0)
  }

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      setQueryString(search)
      setOffset(0)
    }
  }

  return !tracksList ? (
    <Loading />
  ) : (
    <>
      <BodyWrapper>
        <Row>
          <SearchHeader
            text="Tracks"
            setSearch={setSearch}
            handleKeyPress={handleKeyPress}
            search={search}
            onSearch={onSearch}
            searchText="Search Tracks"
          />

          {!error ? (
            <TrackList>
              <Row>
                {tracksList.map((track, index) => {
                  const showTitle =
                    track.name[0].toLowerCase() !== alphabet.toLowerCase() ||
                    index === 0
                  if (showTitle) alphabet = track.name[0]

                  //Attach the ref to the last element
                  if (index + 1 === tracksList.length) {
                    return (
                      <>
                        {showTitle ? (
                          <GroupTitle key={track.name[0].toUpperCase()}>
                            {track.name[0].toUpperCase()}
                          </GroupTitle>
                        ) : null}
                        <Link to={`/track/${track.id}`} key={index}>
                          <TrackImageContainer
                            key={track.id}
                            ref={lastTrackRef}
                          >
                            <TrackImage
                              name={track.name}
                              logo={track.logo}
                              image={track.image}
                              location={`${track.city}, ${track.state}`}
                            />
                          </TrackImageContainer>
                        </Link>
                      </>
                    )
                  } else {
                    return (
                      <>
                        {showTitle ? (
                          <GroupTitle>{track.name[0].toUpperCase()}</GroupTitle>
                        ) : null}
                        <Link to={`/track/${track.id}`} key={index}>
                          <TrackImageContainer key={track.id}>
                            <TrackImage
                              name={track.name}
                              logo={track.logo}
                              image={track.image}
                              location={`${track.city}, ${track.state}`}
                            />
                          </TrackImageContainer>
                        </Link>
                      </>
                    )
                  }
                })}
                {loading ? <Loading /> : null}
              </Row>
            </TrackList>
          ) : (
            <p>Sorry, couldn't fetch Track List at the moment.</p>
          )}
        </Row>
      </BodyWrapper>
    </>
  )
}
