import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SmartBanner from "react-smartbanner"
import "../../node_modules/react-smartbanner/dist/main.css"
import TrackPage from "../components/trackHome/TrackPage"

export default function Tracks() {
  return (
    <Layout maxHeight={true}>
      <SmartBanner title={"Ticket Hoss"} />
      <SEO title="Ticket Hoss" />

      <TrackPage />
    </Layout>
  )
}
