import React from "react"
import LocationSvgOrange from "../../images/location_orange.svg"

import {
  TrackMainImageContainer,
  Image,
  TrackContent,
  TrackName,
  Support,
  LocationIcon,
  LocationText,
  TrackLogo,
  TrackLogoWrapper,
  ImageOverlayWrapper,
} from "../../styles/trackImage.style"

export default function TrackImage({
  image,
  name,
  logo,
  location,
  isSeries = false,
}) {
  return (
    <>
      <TrackMainImageContainer>
        <Image
          src={
            image
              ? image
              : ""
          }
          alt={name}
        />
        <ImageOverlayWrapper>
          <TrackContent>
            <TrackName>{name}</TrackName>
            {isSeries ? null : (
              <Support>
                <LocationIcon src={LocationSvgOrange} alt="Location Icon" />
                <LocationText>{location}</LocationText>
              </Support>
            )}
          </TrackContent>
          <TrackLogoWrapper>
            {logo && <TrackLogo src={logo ? logo : ""} alt="large1872" />}
          </TrackLogoWrapper>
        </ImageOverlayWrapper>
      </TrackMainImageContainer>
    </>
  )
}
