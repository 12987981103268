import { useQuery } from "@apollo/react-hooks"
import { gql } from "graphql-tag"

const GET_TRACKS = gql`
  query GetTracksV2($input: GetTracksV2Input!) {
    getTracksV2(input: $input) {
      id
      name
      image
      logo
      city
      state
    }
  }
`

export function useGetTracks(options = {}) {
  return useQuery(GET_TRACKS, {
    variables: { input: options },
  })
}
