import styled from "styled-components"

export const TrackMainImageContainer = styled("div")({
  "border-color": "transparent",
  position: "relative",
  height: "100%",
})

export const Image = styled.img`
  object-fit: fill;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  width: 327px;
  height: 200px;
  opacity: 1;
  background: rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const TrackContent = styled("div")({
  display: "flex",
  "flex-direction": "column",
  "align-items": "flex-start",
  "flex-grow": "1",
  "flex-shrink": "0",
  "border-color": "transparent",
  position: "absolute",
  bottom: 18,
  left: 16,
})

export const TrackName = styled("span")({
  color: "white",
  height: "auto",
  "text-align": "left",
  "line-height": "32px",
  "margin-inline": "0px",
  fontSize: "1.75em",
  fontWeight: 700,
  letterSpacing: 0,
})

export const Support = styled("div")({
  display: "flex",
  "align-items": "center",
  "flex-shrink": "0",
  "align-self": "stretch",
  "border-color": "transparent",
  position: "relative",
})

export const LocationIcon = styled("img")({
  width: "14px",
  height: "20px",
  "margin-right": "9px",
})

export const LocationText = styled.span`
  color: #fff;
  height: auto;
  text-align: left;
  font-size: 1em;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
  align-self: auto;
  flex-grow: 1;
  font-weight: 700;
  text-transform: uppercase;
`

export const TrackLogo = styled.img`
  border-color: transparent;
  object-fit: contain;
  max-height: 60px;
  width: auto;
  max-width: 112px;
  width: 112px;
`

export const TrackLogoWrapper = styled.div`
  max-width: 35%;
  height: 45px;
  position: absolute;
  display: flex;
  align-items: flex-start;
  top: 16px;
  left: 16px;
  flex-grow: 1;
  flex-shrink: 0;
  max-height: 60px;
`
export const ImageOverlayWrapper = styled.div`
  inset: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  width: 327px;
  height: 200px;

  @media (max-width: 768px) {
    width: 100%;
  }
`
